import { mapGetters, mapActions } from 'vuex';

import TranslationMultipleLanguagesMixin from '~/mixins/TranslationMultipleLanguagesMixin';

export default {
    mixins: [TranslationMultipleLanguagesMixin],

    data() {
        return {
            selectedRegion: this.$locale.regio,
            selectedLanguage: this.$locale.language,
            selectedCurrencyCode: this.$store.getters['currency/getSelectedCurrency'],
            regionByContinent: null,
        };
    },

    computed: {
        ...mapGetters({
            allCurrencies: 'locale/currencies',
            supportedCurrencies: 'currency/getSupportedCurrencies',
            currencyCode: 'currency/getSelectedCurrency',
        }),

        selectedCurrency: {
            get() {
                return this.allCurrencies.find(
                    currency => currency.code.toLowerCase() === this.selectedCurrencyCode.toLowerCase()
                );
            },
            set(currency) {
                this.selectedCurrencyCode = currency.code;
            },
        },

        allLanguages() {
            return this.$nuxt.$locale.getLanguagesOfRegion(this.selectedRegion);
        },

        allRegions() {
            return Array.from(new Set(this.$locale.locales.map(locale => locale.regio)));
        },

        allTargetRegions() {
            return Array.from(
                new Set(this.$locale.locales.filter(locale => locale.target).map(locale => locale.regio))
            );
        },

        allContinents() {
            let continents = [];

            this.$locale.locales.forEach(locale => {
                if (locale.continent !== undefined || locale.continent !== null) {
                    let continent = continents.find(c => c.name === this.getShorthandContinent(locale.continent));

                    if (!continent) {
                        continent = {
                            name: this.getShorthandContinent(locale.continent),
                            regions: [],
                        };

                        continents.push(continent);
                    }
                    const res = continents.find(el => el.name === continent.name);
                    res.regions.push(locale.regio);

                    res.regions = [...new Set(res.regions)];
                }
            });

            const sortByProps = ['eu', 'na', 'sa', 'oc', 'as', 'af', ''];

            continents.sort((x, y) => sortByProps.indexOf(x.name) - sortByProps.indexOf(y.name));

            const firstContinent = continents.filter(res => {
                if (res.regions.find(res => res === this.$locale.regio)) {
                    return res;
                }
                return false;
            })[0];

            continents = continents.filter(obj => {
                return obj.name !== firstContinent.name;
            });

            continents = [firstContinent, ...continents];

            continents.forEach(continent => continent.regions.sort());

            return continents;
        },

        groupedRegions() {
            return this.$locale.locales.reduce((prev, cur) => {
                const entr = prev[cur.continent];
                prev[cur.continent] = (entr || new Set()).add(cur.region);
                return prev;
            }, {});
        },

        selectedRegionSetItem() {
            if (this.selectedRegion)
                return this.regionSet.find(item => item.code === this.selectedRegion.toLowerCase());
            return this.defaultRegionSetItem;
        },
        defaultRegionSetItem() {
            return this.regionSet.find(item => item.code === this.$locale.regio.toLowerCase());
        },
        regionSet() {
            // remove duplicates from the locales
            const regionCodes = Array.from(new Set(this.$locale.locales.map(locale => locale.regio.toLowerCase())));

            const locales = [];
            regionCodes.forEach(regio => {
                locales.push(this.$locale.locales.find(locale => locale.regio.toUpperCase() === regio.toUpperCase()));
            });

            // Map them to usable data entities:
            return locales.map(locale => {
                const translationIdentifier = `country.${locale.regio.toUpperCase()}`;

                let title;
                // in the settingsRegionPopup, we need to show the preferredLanguage
                if (this.preferredLanguage) title = this.translateIn(this.preferredLanguage, translationIdentifier);
                else title = this.translate(translationIdentifier);

                return {
                    // region code is used for displaying the flag
                    code: locale.regio.toLowerCase(),

                    // Translate title of region in current or preferred language:
                    title,

                    // Tags are the country titles in native tongue
                    tags: locale.tags,
                };
            });
        },

        selectedCurrencySetItem() {
            if (this.selectedCurrencyCode)
                return this.currencySet.find(item => item.code === this.selectedCurrencyCode);
            return this.defaultCurrencySetItem;
        },
        defaultCurrencySetItem() {
            return this.currencySet.find(item => item.code === this.$locale.currency);
        },
        currencySet() {
            const nativeLanguages = this.$locale.getNativeLanguagesOfRegion(this.selectedRegion);
            const translationsByCurrency = {};
            nativeLanguages.forEach(languageCode => {
                if (this.filesByLanguage[languageCode]) {
                    for (const [currency, name] of Object.entries(this.filesByLanguage[languageCode])) {
                        if (!translationsByCurrency[currency]) translationsByCurrency[currency] = [];
                        translationsByCurrency[currency].push(name);
                    }
                }
            });

            const currencies = this.$locale.currencies.filter(currency =>
                this.supportedCurrencies.includes(currency.code)
            );
            const mappedCurrencies = currencies.map(currency => ({
                ...currency,
                name: this.filesByLanguage[this.selectedLanguage]
                    ? this.filesByLanguage[this.selectedLanguage][currency.code]
                    : '',
                tags: translationsByCurrency[currency.code],
            }));
            return mappedCurrencies;
        },
    },

    methods: {
        ...mapActions({
            setCurrencyAction: 'currency/setCurrency',
        }),

        getShorthandContinent(name) {
            switch (name) {
                case 'Europe':
                    return 'eu';
                case 'North America':
                    return 'na';
                case 'South America':
                    return 'sa';
                case 'Oceania':
                    return 'oc';
                case 'Asia':
                    return 'as';
                case 'Africa':
                    return 'af';

                default:
                    return '';
            }
        },

        groupByContinents() {
            const groupBy = key => array =>
                array.reduce((objectsByKeyValue, obj) => {
                    const value = obj[key];
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
                    return objectsByKeyValue;
                }, {});

            const groupByContinent = groupBy('continent');

            this.regionByContinent = groupByContinent(this.allRegions);
        },

        setRegion(region, saveInstant = false) {
            if (region.code) this.selectedRegion = region.code;
            else this.selectedRegion = region;

            if (!this.allLanguages.includes(this.selectedLanguage)) {
                this.selectedLanguage = this.allLanguages[0];
            }

            this.selectedCurrency = this.$locale.getCurrencyOfRegion(this.selectedRegion)[0];

            if (saveInstant) {
                this.saveSettings();
            }
        },

        setLanguage(language, saveInstant = false) {
            this.selectedLanguage = language;

            if (saveInstant) {
                this.saveSettings();
            }
        },

        setCurrency(currency, saveInstant = false) {
            this.selectedCurrencyCode = currency.code;

            if (saveInstant) {
                this.saveSettings();
            }
        },

        async saveSettings() {
            const reversedRoute = {
                ...this.$locale.reverseRoute(this.$route),
            };

            if (typeof reversedRoute === 'undefined') {
                console.error('Undefined reversed settings route');
            }
            const currencyFormat = await this.$api.currency.getCurrencyFormat(
                this.selectedLanguage + '-' + this.selectedRegion.toUpperCase(),
                this.selectedCurrencyCode
            );
            this.$store.dispatch('currency/setFormatParts', currencyFormat);

            if (
                this.selectedLanguage !== this.$locale.language ||
                this.selectedRegion.toUpperCase() !== this.$locale.regio
            ) {
                const newRoute = this.$locale.route({
                    ...reversedRoute,
                    language: this.selectedLanguage,
                    regio: this.selectedRegion,
                    iso: this.selectedLanguage + '-' + this.selectedRegion.toUpperCase(),
                });

                await this.$router.replace({
                    ...newRoute,
                    query: {
                        ...newRoute.query,
                        currency: this.selectedCurrency.code,
                    },
                });
            } else {
                await this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        currency: this.selectedCurrency.code,
                    },
                });
            }

            this.setCurrencyAction(this.selectedCurrency);

            try {
                this.$analytics.event('user_save_settings', {
                    region: this.selectedRegion,
                    language: this.selectedLanguage,
                    currency: this.selectedCurrencyCode,
                });
            } catch (error) {
                console.error(error);
            }

            this.$store.dispatch('session/setLocale', {
                region: this.selectedRegion,
                language: this.selectedLanguage,
                currency: this.selectedCurrencyCode.toLowerCase(),
            });

            // Reload, the new locale settings might be live in nextjs
            window.location.reload();
        },
    },
};
